import React from 'react';
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { lan } from "../lan/index.js";
import MetaTags from '../component/MetaTags.jsx';

const JapaneseCodingCancellationRefundPolicy = () => {
    const data = lan.en;
    return (
      <>
      <Navbar />
      
      <div className="container">
      <div className="centered-content">
      <h3>Cancellation & Refund Policy </h3>

      <h4>Last updated on Oct 28th, 2023</h4>

      <h4>1. Cancellation Policy</h4>
      <p>
        We understand that plans can change. Please note the following conditions for our Japanese and Coding classes:
      </p>
      <ul>
        <li>Cancellation requests are only considered if made immediately after placing the order.</li>
        <li>If the classes have already started or if the courses have been accessed, we may not be able to entertain cancellation requests.</li>
        <li>Cancellation requests for our courses can only be accepted up to a certain point in the course duration.</li>
      </ul>

      <h4>2. Refund Policy</h4>
      <p>
        We aim to provide our customers with the best experience. Please review our refund policy:
      </p>
      <ul>
        <li>If you're not satisfied with our courses, please contact our Customer Service team within 2 days of starting the course.</li>
        <li>We will investigate the issue and determine an appropriate solution, which may include a refund.</li>
        <li>Refunds, if approved, may take 6-8 days to process.</li>
      </ul>

      {/* You can customize this content to match your specific policies. */}
    </div>
    </div>
    <Footer value={data} />
    <MetaTags title={"Refund Policy | Koderbox"} />
    </>
  );
};

export default JapaneseCodingCancellationRefundPolicy;
